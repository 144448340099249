/**
 * Installations
 * Installations
 *
 * The version of the OpenAPI document: 1.0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 0 = Unknown, 12 = RemoveDevice, 13 = ResetDevice, 14 = ReprogramDevice, 15 = ReplaceDevice, 20 = CreateProduct, 21 = UpdateProduct, 22 = RemoveProduct, 23 = DetachProduct, 24 = AttachProduct, 30 = CreateRoutine, 31 = UpdateRoutine, 32 = RemoveRoutine, 40 = CreateScene, 41 = UpdateScene, 42 = RemoveScene
 */
export enum ProgrammingObjectives {
    Unknown = 'unknown',
    RemoveDevice = 'removedevice',
    ResetDevice = 'resetdevice',
    ReprogramDevice = 'reprogramdevice',
    ReplaceDevice = 'replacedevice',
    CreateProduct = 'createproduct',
    UpdateProduct = 'updateproduct',
    RemoveProduct = 'removeproduct',
    DetachProduct = 'detachproduct',
    AttachProduct = 'attachproduct',
    CreateRoutine = 'createroutine',
    UpdateRoutine = 'updateroutine',
    RemoveRoutine = 'removeroutine',
    CreateScene = 'createscene',
    UpdateScene = 'updatescene',
    RemoveScene = 'removescene'
}

