/**
 * Installations
 * Installations
 *
 * The version of the OpenAPI document: 1.0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProgrammingActionStatus } from './programming-action-status';


export interface ProgrammingAction { 
    key?: string;
    status?: ProgrammingActionStatus;
    actionType: ProgrammingActionActionTypeEnum;
}
export enum ProgrammingActionActionTypeEnum {
    CreateLogicModelProgrammingAction = 'CreateLogicModelProgrammingAction',
    RemoveDeviceProgrammingAction = 'RemoveDeviceProgrammingAction',
    RemoveLogicModelProgrammingAction = 'RemoveLogicModelProgrammingAction',
    ResetDeviceProgrammingAction = 'ResetDeviceProgrammingAction',
    ResetModelProgrammingAction = 'ResetModelProgrammingAction',
    SetModelParametersProgrammingAction = 'SetModelParametersProgrammingAction',
    SetModelLinkProgrammingAction = 'SetModelLinkProgrammingAction'
};



