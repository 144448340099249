/**
 * Installations
 * Installations
 *
 * The version of the OpenAPI document: 1.0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 0 = Unknown, 10 = ResetDevices, 20 = ResetModels, 30 = RemoveLogicModels, 50 = RebootDevicesAfterCleanup, 100 = CreateLogicModels, 110 = SetModelParameters, 120 = SetModelLinks, 200 = RebootDevices, 210 = RemoveDevices
 */
export enum ProgrammingPhases {
    Unknown = 0,
    ResetDevices = 10,
    ResetModels = 20,
    RemoveLogicModels = 30,
    RebootDevicesAfterCleanup = 50,
    CreateLogicModels = 100,
    SetModelParameters = 110,
    SetModelLinks = 120,
    RebootDevices = 200,
    RemoveDevices = 210
}

